@use '/src/packages/assets/styles/typography';

:global(.go-theme_light) {
  --create-tab-switch-bg: #dfe6f2;
  --create-tab-template-text: rgba(40, 43, 49, 0.6);
  --create-tab-plug-text: rgba(40, 43, 49, 0.6);
  --create-tab-svg-color: #d2dbec;
}

:global(.go-theme_dark) {
  --create-tab-switch-bg: var(--primary-2);
  --create-tab-template-text: var(--secondary-1);
  --create-tab-plug-text: var(--white-color);
  --create-tab-svg-color: var(--primary-2);
}

.modal {
  display: flex;
  flex-direction: column;
  background-color: var(--modal-bg);
  border: var(--modal-border);
  padding: 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 48px 8px 16px;
}

.headerButtons {
  display: flex;
  align-items: center;
  column-gap: 24px;
}

.closeButton {
  padding: 0;
  width: 24px;
  height: 24px;
}

.wrapper {
  margin-top: 37px;
}

.title {
  @extend .h4;
}

.tabName {
  @extend %h2;

  margin-bottom: 36px;
}

.buttonsWrapper {
  justify-content: center;
  display: flex;
  column-gap: 16px;
}

.createEmptyButton {
  padding: 0;
  font-weight: 500;

  :hover {
    text-decoration: none;
  }
}

.switchContainer {
  display: grid;
  align-items: end;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  background: var(--create-tab-switch-bg);
  height: 32px;

  .switchButton {
    font-size: 13px;
    font-weight: 400;
  }
}

.wrapperScroll {
  position: relative !important;

  :global(.ScrollbarsCustom-Scroller) {
    position: relative !important;
  }
}

.row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 16px;
  min-height: 140px;
  height: max-content;
  max-height: 228px;
}

.templates {
  display: grid;
  min-width: 864px;
  max-height: 716px;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  padding: 32px 16px 20px;
  row-gap: 16px;
}

.template {
  display: flex;
  max-width: 196px;
  max-height: 228px;
  flex-direction: column;
  cursor: pointer;
  overflow: hidden;

  border-radius: 8px;
  padding: 4px;

  transition: 0.1s;

  &:hover {
    background-color: rgba(#657997, 0.15);
  }

  &Img {
    --template-bg: var(--modal-bg);
    --template-color: var(--create-tab-svg-color);

    max-width: 196px;
    width: 100%;
    margin-bottom: 16px;
  }

  &Title {
    margin-bottom: 4px;
    font-size: 13px;
    font-weight: 600;
    color: var(--text-1);
  }

  &Description {
    @extend .fontBase;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    text-overflow: ellipsis;
    overflow: hidden;

    color: var(--create-tab-template-text);
  }
}

.plug {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;

  &Title {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    margin-bottom: 4px;
    color: var(--text-1);
  }

  &Desc {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    color: var(--create-tab-plug-text);
  }
}
