@use '/src/packages/assets/styles/typography';

.column {
  @extend .fontBase;

  width: 100%;
  padding: 8px 16px 16px 16px;
  background: var(--settings-grid-column-bg);
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.settingsBlock {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  @extend .body-m-bold;
}

.settingsOption {
  @extend .body-s;

  display: flex;
  align-items: center;
  gap: 8px;
  padding-top: 16px;
  margin-bottom: 16px;
}
