@font-face {
  font-family: 'Inter';
  src: url('../fonts/inter-bold.woff2') format('woff2'),
    url('../fonts/inter-bold.woff') format('woff');
  font-weight: bold; /* 700 */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/inter-medium.woff2') format('woff2'),
    url('../fonts/inter-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/inter-regular.woff2') format('woff2'),
    url('../fonts/inter-regular.woff') format('woff');
  font-weight: normal; /* 400 */
  font-style: normal;
  font-display: swap;
}
