@use '/src/packages/assets/styles/typography';
@use 'src/packages/assets/styles/templates';

:global(.go-theme_light) {
  --basic-params-inactive-panelButton-bg: rgba(80, 101, 155, 0.1);
  --basic-params-active-panelButton-bg: rgba(57, 84, 208, 1);
  --basic-params-inactive-text-color: rgba(40, 43, 49, 0.8);
  --basic-params-inactive-panelButton-border: transparent;
  --basic-params-active-text-color: var(--white-color);
  --basic-params-action-color: rgba(57, 84, 208, 1);
}

:global(.go-theme_dark)  {
  --basic-params-inactive-panelButton-bg: rgba(7, 7, 19, 0.3);
  --basic-params-active-panelButton-bg: rgba(96, 92, 255, 1);
  --basic-params-inactive-text-color: var(--white-color-50);
  --basic-params-inactive-panelButton-border: rgba(40, 49, 63, 1);
  --basic-params-active-text-color: var(--white-color);
  --basic-params-action-color:  rgba(116, 113, 255, 1);
}

.column {
  @extend .fontBase;

  width: 100%;
  padding: 8px 16px 16px 16px;
  background: var(--settings-grid-column-bg);
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.header {
  @extend .body-s;
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 16px;
}

.headerSelectWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--text-1);
}

.headerSelect {
  width: 176px;
}

.actionsPanel {
  @extend .caption-fs13;
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  gap: 8px;
  align-items: center;
  &__disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.actionsPanelSwitcher {
  @extend .caption-fs13;
  width: 159px;
  height: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  transition: var(--transition);
}

.switcherButton {
  @extend %ghost-button;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--basic-params-inactive-panelButton-border);
  background-color: var(--basic-params-inactive-panelButton-bg);
  transition: var(--transition);

  &__active {
    background-color: var(--basic-params-active-panelButton-bg);
    border: 1px solid rgba(40, 49, 63, 1);
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.text {
  color: var(--basic-params-inactive-text-color);
  transition: var(--transition);

  &__active {
    color: var(--basic-params-active-text-color);
  }
}

.actionWrapper {
  padding: 0 8px;
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--basic-params-action-color);
  cursor: pointer;

  &__disabled {
    opacity: 0.5;
    cursor: inherit;
    pointer-events: none;
  }
}

.actionIcon {
  font-size: 16px;
}

.parametersSelectionTitleWrapper {
  display: grid;
  grid-template-columns: 250px 280px 270px 100px;
  column-gap: 16px;
  margin-bottom: 12px;
}

.parametersTitle {
  @extend .h4;
  color: var(--contrast);
  margin-bottom: 4px;
  padding-left: 8px;
}

.parametersSelectionGrid {
  @extend .dash-body-regular;
  display: grid;
  grid-template-columns: 250px 280px 270px 100px 20px 105px;
  column-gap: 16px;
  grid-template-rows: auto;
  row-gap: 8px;
  align-items: center;

  &_parameter {
    padding-left: 8px;
    color: var(--contrast);
  }

  &_warning {
    padding-left: 8px;
    color: var(--red-color);
  }

  &__disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.selectName {
color: var(--text-1);
}

.selectMnemo {
  color: var(--brand-4);
}

.selectSearch {
  input {
    color: var(--text-2);
  }
}
