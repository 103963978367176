$header-offset: 32px;

:global(.go-theme_light) {
  --workbehch-item-bg: var(--white-color);
  --workbench-item-border: rgba(183, 196, 222, 1);
}

:global(.go-theme_dark) {
  --workbehch-item-bg: var(--primary-2);
  --workbench-item-border: var(--primary-1);
}

.contentWrapper {
  width: 100%;
  height: calc(100% - $header-offset);
  margin-top: $header-offset;
  display: flex;
  flex-direction: column;

  &FSMode {
    height: 100%;
    margin-top: 0;
    background-color: var(--main-wrapper-layout-bg);
  }
}

:global {
  :local(.content) {
    .react-grid-placeholder {
      background-color: var(--brand-1);
      opacity: 0.15;
    }
  }
}

.content {
  width: 100%;
}

.itemWrapper {
  padding: 1px 0;
  transition: none;

  &Fullscreen {
    transition-duration: unset;
    // в полноэкранном режиме перезаписываем transform, который устанавливает react-grid-layout
    transform: unset !important;

    :global(.react-resizable-handle) {
      display: none;
    }
  }

  &:not(:hover) {
    :global(.ScrollbarsCustom-Track) {
      opacity: 0;
    }
  }
}

.item {
  position: relative;
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background-color: var(--workbehch-item-bg);
  border-radius: 2px;
  border: 1px solid var(--workbench-item-border);
  box-shadow: 0px 0 22px rgba(2, 2, 7, 0.16);

  &Fullscreen {
    position: fixed;
    top: 36px;
    left: 4px;
    right: 4px;
    bottom: 0;
    max-height: calc(100vh - 42px);

    &WithoutHeader {
      max-height: calc(100vh - 8px);
      top: 2px;
    }
  }

  &Hidden {
    opacity: 0;
    visibility: hidden;
  }
}

.exitFSButton {
  z-index: 1000;
  position: fixed;
  left: 12px;
  bottom: 24px;
  width: 40px;
  font-size: 32px;
  color: var(--text-1);
  border-radius: 12px;
  background: var(--main-wrapper-layout-bg);
  height: 40px;
}

.workbenchWidthWrapper {
  flex-grow: 1;
}

.gridOverflow {
  min-width: 320px;
  height: 100%;
}

.workbenchScrollers {
  :global(> .ScrollbarsCustom-Track) {
    z-index: 1000;
  }
}

.hiddenWidgetResizeHandle {
  visibility: hidden;
}
