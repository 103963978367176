.wrapper {
  width: 100%;
  padding-top: 40px;
  padding-right: 220px;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.text {
  width: 190px;
  padding-right: 30px;
  padding-top: 8px;
}